import axios from "@/axios.config.js";

const staticPageShow = (type) => axios.get(`/api/v1/static_pages/${type}`);

const staticPageUpdate = (type,value) => axios.put(`/api/admin/v1/static_pages/${type}`,{
  value
});

export {
  staticPageShow,
  staticPageUpdate
}